/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from 'react';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import _ from 'lodash';
import { getTerminateSchema } from './terminate-vehicle-form-schema';

const TerminateVehicleForm = ({
  onSubmit,
  terminationData,
  setTerminationData,
}) => {
  const { vehicle } = useVehicle();
  // get assignment date by way of assigned indicator begin time
  const assignmentDate = new Date(
    _.find(vehicle?.assetLifecycle, {
      lifeCycle: { lifecycleIndicatorId: '603' },
    })?.lifecycleIndicatorBeginDate,
  );

  const terminateVehicleRef = useRef(null);

  useEffect(() => {
    if (!terminateVehicleRef.current) return () => {};
    const subscription = terminateVehicleRef.current.watch((data) => {
      setTerminationData({
        ...terminationData,
        ...data,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [terminateVehicleRef.current]);

  const today = new Date();

  // start of month
  let minDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
  ).toISOString();

  if (assignmentDate?.getMonth() === today.getMonth()) {
    minDate = assignmentDate.toISOString();
  }

  const defaultFormData = useMemo(
    () => ({
      terminationDate: '',
    }),
    [],
  );
  const formContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fieldLayout: 'horizontal',
        fields: [
          {
            id: 'terminationDate',
            type: 'datepicker',
            label: 'Termination date',
            required: true,
            element: {
              control: {
                maxDate: today.toISOString(),
                minDate,
              },
            },
          },
          {
            id: 'endOdometer',
            type: 'number',
            label: 'End mileage',
            required: true,
          },
        ],
      },
      {
        fields: [
          {
            id: 'comment',
            type: 'textarea',
            label: 'Remarks',
            element: {
              control: {
                maxLength: 100,
                countdown: true,
              },
            },
          },
        ],
      },
    ],
  };
  return (
    <FormGenerator
      ref={terminateVehicleRef}
      schema={getTerminateSchema(vehicle?.inventoryStatusMileage)}
      id="terminate-vehicle-form"
      data-testid="terminate-vehicle-form"
      defaultValues={defaultFormData}
      content={formContent}
      onSubmit={onSubmit}
      useFormProps={{
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }}
    />
  );
};

export default TerminateVehicleForm;
