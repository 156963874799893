import React, { useState, useMemo } from 'react';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import { useMutation } from '@apollo/client';
import {
  Button,
  DetailsTable,
  Spinner,
  useModal,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { assetLookupOptions } from 'utilities/consts';
import { VehiclePropType } from 'utilities/types';
import { UPDATE_VEHICLE_FIELDS } from 'services/data-layer';
import useUser from 'utilities/use-user';
import moment from 'moment';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
  canUpdateGFVehicleCustomer,
} from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { filterSubmitObj } from 'utilities/form-generator-controls/helper';
import { omitKeys } from 'components/vehicle-details/helpers/utils';
import {
  formatStatePlateExp,
  getAssignmentDateFromLifecycle,
  extractMonthYear
} from 'utilities/common';
import { emdash } from 'components/common';
import GFVehicleEdit from './gf-vehicle-edit';
import {
  getLicensePlate,
  getVINVerificationStatus,
  displayModel,
} from '../helpers/vehicle-details-helper';
import { fieldGetter } from '../../../helpers/field-getter';
import { useVehicle } from '../../../vehicle-context-provider';
import VehicleEdit from './vehicle-edit';
import SectionBlock from './section-block';

const OTC4VehicleGroup = ['AO', 'GF'];

const VehicleDetails = ({ data }) => {
  const [editVehicle, setEditVehicle] = useState(false);
  const { setSectionMsg, refetchVehicle } = useVehicle();

  const {
    isOpen: isGFModalOpen,
    openModal: openGFModal,
    closeModal: closeGFModal,
  } = useModal();
  const ability = useAppAbility();
  const { isRole } = useUser();
  const hasFMVRSAdminRole = isRole('FMVRSAdminRole');

  const assignmentDate = getAssignmentDateFromLifecycle(data?.assetLifecycle);

  const canEditGFVehicle =
    isFeatureEnabled('edit-gf-vehicle') &&
    data?.ownershipTypeCode === 'GF' &&
    (canUpdateGFVehicleAdmin(ability) || canUpdateGFVehicleFSR(ability));

  const canEditAOVehicle =
    data?.ownershipTypeCode === 'AO' &&
    (canUpdateGFVehicleAdmin(ability) || canUpdateGFVehicleCustomer(ability));
  const executiveFleetOptions = {
    Y: 'Yes',
    N: 'No',
  };

  const [updateVehicleField, { loading }] = useMutation(UPDATE_VEHICLE_FIELDS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      refetchVehicle();
      setSectionMsg({
        type: 'success',
        message: 'Vehicle was successfully updated.',
      });
      closeGFModal();
    },
    onError: ({ graphQLErrors }) => {
      const gqErrors = graphQLErrors.map((error) => {
        if (error?.extensions?.code.includes('Petroleum-Dedicated')) {
          return `${error?.message}: ${error?.extensions?.code}`;
        }
        return `${error?.message}`;
      });
      setSectionMsg({
        type: 'error',
        message: `Error occurred while updating vehicle details: ${gqErrors.join(
          ' ',
        )}`,
      });
    },
  });

  const saveVehicleChanges = (fields) => {
    if (fields?.length) {
      updateVehicleField({
        variables: {
          vin: data.id,
          fields,
        },
      });
      setEditVehicle(false);
    }
  };

  const fastReportableOptions = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  const formattedStateTagExpirationDate = data?.ald?.stateTagExpirationDate
  ? formatStatePlateExp(data.ald.stateTagExpirationDate)
  : emdash;

  const tableData = useMemo(() => {
    let rows =
      data.ownershipTypeCode === 'GF'
        ? [
            [
              'Assignment date',
              assignmentDate
                ? moment.utc(assignmentDate).format('MM/DD/YYYY')
                : emdash,
            ],
          ]
        : [];

    rows = [
      ...rows,
      ...[
        ['VIN', data.id],
        [
          'VIN verified by',
          getVINVerificationStatus(
            data.pre1981,
            data.internationalVin,
            data.unverifiedVin,
            data.nhtsaVerified,
          ),
        ],
        [
          'Vehicle odometer',
          data.newOdometerReading?.odometer
            ? data.newOdometerReading?.odometer
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : data.assetAcquisition?.deliveredMiles || '',
        ],
        ['Year', data.modelYear],
        ['Make', data.vehicleMake?.makeName],
        [
          'Model',
          fieldGetter([
            {
              field: displayModel(data),
            },
          ]),
        ],
        ['Vehicle series', data.vehicleSeries],
        ['Trim', data.vehicleTrim],
      ],
    ];

    if (OTC4VehicleGroup.includes(data.ownershipTypeCode))
      rows.push(['Vehicle group', data.itemType]);

    rows.push(
      ['Color', data?.makeColor?.name],
      ['Fuel type', data.fuel?.description],
      [
        'Fuel tank capacity',
        data?.assetTechnical?.assetTechnicalIce?.tankSize
          ? `${data?.assetTechnical?.assetTechnicalIce?.tankSize} gal`
          : null,
      ],
      [
        'FAST reportable',
        fastReportableOptions.find(({ value }) => value === data.fastReportable)
          ?.label,
      ],
      [
        'GVWR',
        data.assetTechnical?.gvwr &&
          `${data.assetTechnical.gvwr
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}  lb`,
      ],
    );
    if (data.ownershipTypeCode === 'AO') {
      rows.push(
        [
          'VAM exempt',
          assetLookupOptions[data.assetUsage?.vamExempt] || emdash,
        ],
        [
          'Executive fleet',
          executiveFleetOptions[data.assetUsage?.executiveFleet] || emdash,
        ],
        [
          'License plate',
          getLicensePlate(
            data.exemptPlate,
            data?.tagNumber,
            data?.tag?.expirationDate,
          ),
        ],
        ['State plate', data.ald?.stateTag || emdash],
        ['Agency use 1', data.assetUsage?.agencyUse1],
        ['Agency use 2', data.assetUsage?.agencyUse2],
        ['Agency use 3', data.assetUsage?.agencyUse3],
        ['Agency use 4', data.assetUsage?.agencyUse4],
      );
    }

    if (data.ownershipTypeCode === 'GF') {
      rows.push(
        [
          'License plate',
          getLicensePlate(
            data.exemptPlate,
            data?.tagNumber,
            data?.tag?.expirationDate,
          ),
        ],
        ['State plate', data.ald?.stateTag || emdash],
        [
          'State plate expiration date',
          formattedStateTagExpirationDate,
        ],
        ['AFI Number', data.ald?.afiNumber || emdash],
      );
    }
    return rows;
  }, [data]);

  const adminEditFields = [
    'makeColorName',
    'fuelCode',
    'tankSize',
    'fastReportable',
    'gvwr',
    'stateTag',
    'vamExempt',
    'executiveFleet',
    'stateTagExpirationDate',
    'afiNumber',
  ];

  const FSREditFields = [
    'fuelCode',
    'tankSize',
    'gvwr',
    'stateTag',
    'stateTagExpirationDate',
    'afiNumber',
  ];

  const handleGFVehicleInformation = (formData) => {
    const isAdmin = canUpdateGFVehicleAdmin(ability);
    const isFsr = canUpdateGFVehicleFSR(ability);

    let fields = {};
    if (isAdmin) {
      fields = {
        ...fields,
        ...filterSubmitObj({ formData, fields: adminEditFields }),
      };
    } else if (isFsr) {
      fields = {
        ...fields,
        ...filterSubmitObj({ formData, fields: FSREditFields }),
      };
    } else {
      return;
    }

    if (fields.executiveFleet || fields.vamExempt) {
      const assetUsage = {
        assetId: data.uuid,
        ...(fields?.vamExempt && {
          vamExempt: fields?.vamExempt,
        }),
        ...(fields?.executiveFleet && {
          executiveFleet: fields?.executiveFleet === 'true' ? 'Y' : 'N',
        }),
      };

      fields.assetUsage = assetUsage;
    }

    if (
      fields.fuelCode ||
      fields.afiNumber ||
      fields.stateTag ||
      fields.stateTagExpirationDate
    ) {
      const ald = {
        assetId: data.uuid,
        ...(fields?.afiNumber && {
          afiNumber: fields?.afiNumber,
        }),
        ...(fields?.stateTag && {
          stateTag: fields?.stateTag,
        }),
        ...(fields?.stateTagExpirationDate && {
          stateTagExpirationDate:
            extractMonthYear(fields?.stateTagExpirationDate) || null,
        }),
      };

      fields.ald = ald;
    }
    fields = omitKeys(fields, [
      'vamExempt',
      'executiveFleet',
      'afiNumber',
      'stateTag',
      'stateTagExpirationDate',
    ]);

    fields = Object.entries(fields).map(([k, v]) => {
      if (k === 'ald' || k === 'assetUsage') {
        return { field: k, values: v };
      }
      return { field: k, value: v };
    });

    updateVehicleField({
      variables: {
        vin: data.id,
        fields,
      },
    });
  };
  return (
    <>
      <SectionBlock title="Details">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={tableData}
        />
        <div className="margin-top-2">
          {canEditAOVehicle && (
            <Button
              data-testid="ao-vehicle-edit-button"
              variant="outline"
              label="Edit"
              onClick={() => setEditVehicle(true)}
            />
          )}

          {canEditGFVehicle && (
            <Button
              label="Edit"
              data-testid="gf-vehicle-edit-button"
              onClick={() => {
                openGFModal();
              }}
              variant="outline"
              className="bg-white margin-top-2"
              aria-label="edit gf vehicle"
            />
          )}

          <GFVehicleEdit
            vehicle={data}
            onClose={() => {
              closeGFModal();
            }}
            onSave={handleGFVehicleInformation}
            isGFModalOpen={isGFModalOpen}
          />

          {editVehicle && (
            <VehicleEdit
              onSave={saveVehicleChanges}
              onClose={() => setEditVehicle(false)}
              vehicle={data}
              fastReportableOptions={fastReportableOptions}
              hasFMVRSAdminRole={hasFMVRSAdminRole}
            />
          )}
        </div>
      </SectionBlock>
      {loading && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

VehicleDetails.propTypes = {
  data: PropTypes.shape(VehiclePropType).isRequired,
};

export default VehicleDetails;
