/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AFPTable,
  Button,
  EmptyState,
  Menu,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { isArrayOfConstValShallowEqual } from 'utilities/common';
import { emdash } from 'components/common';
import { useCustomerAccounts } from '../providers/customer-account-provider';
import CaTableRowDetail from '../widgets/ca-table-row-detail';
import { useCustomerAccountFilter } from '../providers/filter-provider';
import { isFeatureEnabled } from '../../../utilities/feature-toggle';

const actionList = [
  {
    icon: 'edit',
    label: 'Edit account details',
    action: 'editCustomerAccount',
    operation: VMSOperations.Update,
  },
  {
    icon: 'cancel',
    label: 'Delete account',
    action: 'deleteCustomerAccount',
    operation: VMSOperations.Delete,
  },
];

const tableRef = React.createRef();

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};

const CustomerAccountListingTable = () => {
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState(
    '`primaryContactAssociation.pointOfContact.pocEmailAddress` ASC',
  );
  const ability = useAppAbility();
  const {
    getCustomerAccountsList,
    customerAccountsList,
    customerAccountsListLoading,
    dispatchAction,
    selectedCustomerAccounts,
    resetModal,
  } = useCustomerAccounts();
  const history = useHistory();
  const { filters } = useCustomerAccountFilter();
  const getData = () => {
    getCustomerAccountsList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters,
      },
    });
  };

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
    resetModal();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [paginationState, order, filters]);

  // Reset pagination when filters change
  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters]);

  const handleSelectedAction = (action, row) => {
    dispatchAction('SET_CUSTOMER_ACCOUNT_SELECTED', row.original);
    switch (action) {
      case 'editCustomerAccount':
        history.push(
          `/customer-accounts/update/${encodeURIComponent(
            row.original.customerId,
          )}`,
        );
        break;
      case 'viewCustomerAccount':
        history.push(
          `/customer-accounts/view/${encodeURIComponent(
            row.original.customerId,
          )}`,
        );
        break;
      case 'deleteCustomerAccount':
        dispatchAction(
          'SET_CUSTOMER_ACCOUNT_MODAL_MODE',
          'DELETE_CUSTOMER_ACCOUNT',
        );
        dispatchAction(
          'SET_CUSTOMER_ACCOUNT_TO_DELETE',
          row.original.customerId,
        );
        break;
      default:
        break;
    }
    dispatchAction('SET_SHOW_CUSTOMER_ACCOUNT_MODAL', true);
  };

  const allowedActions = actionList.filter((action) => {
    return ability.can(action.operation, VMSSubjects.CUSTOMER_ACCOUNT);
  });

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Account name',
        accessor: 'accountName',
        sortable: true,
        Cell: ({ value, row }) => (
          <Button
            variant="unstyled"
            label={value}
            onClick={() => handleSelectedAction('viewCustomerAccount', row)}
          />
        ),
      },
      {
        Header: 'Agency POC',
        sortable: true,
        accessor: 'primaryContactAssociation.pointOfContact.pocFirstName',
        Cell: ({ row }) => {
          return (
            <>
              {row?.original?.primaryContactAssociation?.pointOfContact
                ?.pocFullName && (
                <span>{`${row?.original?.primaryContactAssociation?.pointOfContact?.pocFullName}`}</span>
              )}
            </>
          );
        },
      },
      {
        Header: 'FSR',
        accessor: 'fsrUserEmail',
        sortable: true,
        Cell: ({ row }) => (
          <>
            {row?.original?.fsrUser && (
              <span>{`${row.original.fsrUser?.firstName} ${row.original.fsrUser?.lastName}`}</span>
            )}
          </>
        ),
      },
      {
        Header: 'No. of vehicles',
        accessor: 'vehicleCount',
        sortable: true,
        Cell: ({ value, row }) =>
          value === 0 ? (
            <span>{value}</span>
          ) : (
            <Button
              variant="unstyled"
              label={value}
              // TODO: legacyCustomerNumber not exists for new accounts
              onClick={() =>
                history.push({
                  pathname: '/vehicles',
                  state: {
                    legacyCustomerNumber: row.original.legacyCustomerNumber,
                  },
                })}
            />
          ),
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: ({ row }) => {
          if (
            allowedActions?.length === 0 ||
            !isFeatureEnabled('customer-accounts-change')
          ) {
            return <>{emdash}</>;
          }
          return (
            <div className="height-4 width-4 margin-left-05em">
              <Menu
                actionIconSize="usa-icon--size-4"
                menuItems={allowedActions}
                onActionClick={(action) => {
                  handleSelectedAction(action, row);
                }}
                iconColor="text-primary"
                menuDialogState="hover"
              />
            </div>
          );
        },
      },
    ];

    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="display-flex flex-justify-center">
        <div className="grid-col-11">
          <CaTableRowDetail customerAccount={original} />
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="customer-account-listing-table"
        columns={columns}
        data={
          !customerAccountsListLoading ? customerAccountsList?.rows || [] : []
        }
        defaultSort={order}
        onSort={setOrder}
        selectable
        onRowSelect={({ selectedFlatRows }) => {
          const accountIds = selectedFlatRows.map(
            ({ original }) => original.customerId,
          );
          if (
            !isArrayOfConstValShallowEqual(
              selectedFlatRows.map(({ original }) => original.customerId),
              selectedCustomerAccounts,
            )
          ) {
            dispatchAction('SET_SELECTED_CUSTOMER_ACCOUNTS', accountIds);
          }
        }}
        expandable
        renderRowSubComponent={renderRowSubComponent}
      />
      {customerAccountsListLoading && <Spinner className="padding-y-9" />}
      {customerAccountsList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={customerAccountsList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!customerAccountsList || customerAccountsList?.rows?.length === 0) &&
        !customerAccountsListLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">
              No Customer Accounts available
            </div>
          </div>
        )}
    </>
  );
};

export default CustomerAccountListingTable;
