import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel, Spinner } from '@gsa/afp-component-library';
import { GET_OFFICES_BY_PERMISSION } from '../../../services/data-layer';

const { FilterPanelField, FilterPanelFilterShape, useFilterPanel } =
  FilterPanel;

const OfficeFilterItem = ({
  filter,
  subject,
  operation,
  agencyFilterPath,
  bureauFilterPath,
  officeFilterPath,
}) => {
  const [agencyCode, setAgencyCode] = useState();
  const [bureauCode, setBureauCode] = useState([]);
  const [selectedOfficeCode, setSelectedOfficeCode] = useState([]);
  const [options, setOptions] = useState([]);

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [getOffices, { data: officeOptions, loading }] = useLazyQuery(
    GET_OFFICES_BY_PERMISSION,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    const agencyFilter =
      getFilterFromState(agencyFilterPath) ||
      getFilterFromState('$customer.customer_agency_code$') ||
      getFilterFromState('customerAgencyCode') ||
      {};

    setAgencyCode(agencyFilter.value);

    const bureauFilter =
      getFilterFromState(bureauFilterPath) ||
      getFilterFromState('$customer.customer_bureau_code$') ||
      getFilterFromState('customerBureauCode') ||
      {};

    setBureauCode(bureauFilter.value);

    const officeFilter =
      getFilterFromState(officeFilterPath) ||
      getFilterFromState('customerSubSectionCode') ||
      {};
    setSelectedOfficeCode(officeFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);
    if (agencyCode && bureauCode.length === 1) {
      getOffices({
        variables: {
          agencyCode,
          bureauCode: bureauCode[0],
          operation,
          subject,
          order: [['bureauCode', 'ASC']],
        },
      });
    }
  }, [bureauCode]);

  useEffect(() => {
    if (officeOptions?.getOfficesByPermission) {
      setOptions([
        ...officeOptions?.getOfficesByPermission.map(
          ({ officeCode, prefixedName }) => ({
            value: officeCode,
            label: prefixedName,
          }),
        ),
      ]);
    }
  }, [officeOptions]);

  if (loading) {
    return <Spinner />;
  }

  if (bureauCode?.length > 1) {
    return <p>Cannot filter by office when multiple bureaus are selected</p>;
  }

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          options,
          value: selectedOfficeCode?.length ? selectedOfficeCode : [],
        }}
      />
    </div>
  );
};

OfficeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
  subject: PropTypes.string,
  operation: PropTypes.string,
  agencyFilterPath: PropTypes.string,
  bureauFilterPath: PropTypes.string,
  officeFilterPath: PropTypes.string,
};

export default OfficeFilterItem;
